import arreniaApiService from "../core/services/arreniaapi.service";

export default {
  getPOSs(guid) {
    return arreniaApiService.get(`pos/check?abonnement=${guid}`);
  },
  getPosFiles(syncId) {
    return arreniaApiService.get(`pos/files?syncId=${syncId}`);
  },
  geRemoveFiles(filesGuids) {
    return arreniaApiService.post(`file/remove`, filesGuids);
  },
  
};
