<template>
  <div>
    <!--begin::Dashboard-->
    <b-overlay :show="isLoading" rounded="sm">
      <div
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        role="alert"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-primary svg-icon-xl">
            <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Tools/Compass.svg-->
            <i class="fas fa-info text-primary"></i>
            <!--end::Svg Icon-->
          </span>
        </div>
        <div class="alert-text" v-if="poss.length == 0">
          Aucun POS trouvé pour votre abonnement.
        </div>
        <div class="alert-text" v-if="poss.length > 0">
          Selectionner un POS pour voir le detail.
        </div>
      </div>
    </b-overlay>
    <div class="row">
      <div class="col-xl-4" v-for="pos in poss" :key="pos.syncId">
        <!--begin::Stats Widget 1-->
        <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
          <!--begin::Body-->
          
          <div class="card-body">
            <a
              style="cursor: pointer"
              class="card-title font-weight-bold text-dark text-hover-primary font-size-h5"
              @click="goToPos(pos.syncId)"
              >{{ pos.name }} <span class="label label-primary label-inline" v-if="pos.arreniaCode">{{pos.arreniaCode}}</span></a
            >
            <div class="separator separator-dashed mt-5 mb-5"></div>
            <div
              class="text-inverse-white font-weight-bolder font-size-h6 mb-2 mt-5"
            >
              À recevoir : {{ pos.toReceiveFilesCount }}
            </div>
            <div
              class="text-inverse-white font-weight-bolder font-size-h6 mb-2 mt-5 text-success"
            >
              Reçus : {{ pos.receivedFilesCount }}
            </div>
            <div
              class="text-inverse-white font-weight-bolder font-size-h6 mb-2 mt-5 text-danger"
            >
              Erreurs : {{ pos.errorFIlesCount }}
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 1-->
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import PosService from "../../services/pos.service";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      isLoading: false,
      poss: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Accueil" }]);
    this.getPOSs();
  },
  methods: {
    getPOSs() {
      this.isLoading = true;
      PosService.getPOSs(this.currentSubscription.SubscriptionGuid)
        .then((result) => {
          this.poss = result.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    goToPos(guid) {
      this.$router.push({ name: "pos-detail", params: { guid: guid } });
    },
  },
  computed: {
    ...mapGetters(["currentSubscription"]),
  },
};
</script>
